let CAMPAIGN_ENDPOINT;
let OFFERS_ENDPOINT;

try {
  CAMPAIGN_ENDPOINT =
    (process && process.env.CONTEXT_ENGINE_CAMPAIGNS_ENDPOINT) || '';
  OFFERS_ENDPOINT =
    (process && process.env.CONTEXT_ENGINE_OFFERS_WIDGET_ENDPOINT) || '';
} catch (err) {}

const fetchCampaignError = async res => {
  const noContent = res.status === 204;
  if (res.ok && !noContent) return;

  throw {
    name: 'Request error',
    status: res.status,
    statusText: res.statusText,
    requestText: noContent ? 'No content' : await res.text()
  };
};

/**
 * Fetches offers from CMS.
 *
 * @param {Object} [params={}] The url search parameters in a key-value pair data structure.
 * @returns {Promise} Returns a promise that resolves to the offers data or rejects if request fails for any reason.
 */
const fetchOffers = async (params = {}) => {
  const urlParams = new URLSearchParams(params).toString();

  const res = await fetch(`${OFFERS_ENDPOINT}?${urlParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  await fetchCampaignError(res);

  return await res.json();
};

const fetchWidget = async (slotId, overrideUrl) => {
  const hostRaw = {
    referer: overrideUrl || window.location.href,
    slot: slotId || ''
  };

  const host = window.btoa(JSON.stringify(hostRaw));

  const res = await fetch(`${CAMPAIGN_ENDPOINT}?json&host=${host}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  await fetchCampaignError(res);

  return await res.json();
};

export const http = {
  fetchOffers,
  fetchWidget
};
