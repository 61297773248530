let OFFERS_CLICKOUT_ENDPOINT;

try {
  OFFERS_CLICKOUT_ENDPOINT =
    (process && process.env.OFFERS_CLICKOUT_SERVICE_URL) || '';
} catch (err) {}

export default function clickoutServiceUrlMap(offers, gtmId, deviceType) {
  return offers.map((item, index) => {
    const clickoutData = new URLSearchParams();
    clickoutData.append('offerId', item.id);
    clickoutData.append('offerPosition', index);
    clickoutData.append('pageUrl', window.location.pathname);
    if (deviceType) clickoutData.append('deviceType', deviceType);
    if (gtmId) clickoutData.append('gtmId', gtmId);

    return {
      ...item,
      clickoutUrl: {
        url: `${OFFERS_CLICKOUT_ENDPOINT}?${clickoutData}`,
        original: item.clickoutUrl.url
      }
    };
  });
}
